import { useCallback } from 'react'

import Mixpanel from 'Services/Mixpanel'

const useMixpanel = () => {
  const trackEvent = useCallback(
    (event: string, properties?: Record<string, any>) => {
      try {
        Mixpanel.track(event, properties)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(`Mixpanel track error: ${event}`, error)
      }
    },
    [],
  )

  return { trackEvent }
}

export default useMixpanel
