export const MIXPANEL_EVENTS = {
  // General page visiting
  HOME_PAGE_VIEWED: 'Home Page Viewed',
  PRICING_PAGE_VIEWED: 'Pricing Page Viewed',
  USE_CASES_PAGE_VIEWED: 'Use Cases Page Viewed',
  FEATURES_PAGE_VIEWED: 'Features Page Viewed',
  API_PAGE_VIEWED: 'Api Page Viewed',
  WORKLAIR_COMMUNITY_PAGE_VIEWED: 'Worklair Community Page Viewed',
  HELP_CENTER_PAGE_VIEWED: 'Help Center Page Viewed',
  AFFILIATE_PAGE_VIEWED: 'Affiliate Page Viewed',

  // Legal page visiting
  TERMS_OF_SERVICE_PAGE_VIEWED: 'Terms of Service Page Viewed',
  PRIVACY_POLICY_PAGE_VIEWED: 'Privacy Policy Page Viewed',
  IMPRESSUM_PAGE_VIEWED: 'Impressum Page Viewed',

  // Form visiting
  SIGN_UP_FORM_OPENED: 'Sign Up Form Opened',
  CONTACT_SALES_FORM_OPENED: 'Contact Sales Form Opened',
  REGISTER_BECOME_PARTNER_FORM_OPENED: 'Register Become Partner Form Opened',
  SUBMIT_REFFERAL_FORM_OPENED: 'Submit Your Refferal Form Opened',
  REQUEST_DEMO_FORM_OPENED: 'Request Demo Form Opened',

  // Form submitting
  SIGN_UP_NEW_USER: 'Sing Up Form | New User Registered',
  REGISTER_BECOME_PARTNER_FORM_SUBMITTED:
    'Register to Become a Partner Form Submitted',
  SUBMIT_REFFERAL_FORM_SUBMITTED: 'Submit Your Refferal Form Submitted',
  CONTACT_SALES_FORM_SUBMITTED: 'Contact Sales Form Submitted',
  REQUEST_DEMO_FORM_SUBMITTED: 'Request Demo Form Submitted',

  // Downloads
  DOWNLOAD_FROM_APP_STORE_BUTTON_CLICKED:
    'Download from App Store Button Clicked',
  DOWNLOAD_FROM_GOODLE_PLAY_BUTTON_CLICKED:
    'Download from Goodle Play Button Clicked',

  // Other
  UNKNOWN_EVENT: 'Unknown Event',
} as const

export type MixpanelEvent = keyof typeof MIXPANEL_EVENTS
