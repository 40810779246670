import Envs from 'Services/Envs'

export const APP_ENV = Envs.get('ENV')

export const IS_PRODUCTION = APP_ENV === 'production'
export const IS_STAGING = APP_ENV === 'staging'
export const IS_DEVELOPMENT = APP_ENV === 'development'

export const appearance = {
  theme: 'main',
}

const apiProtocol = Envs.get('API_SSL') === 'true' ? 'https' : 'http'
const socketProtocol = Envs.get('API_SSL') === 'true' ? 'wss' : 'ws'

export const APP_NAME = Envs.get('NAME')

export const FRONTEND_URL = Envs.get('FRONTEND_URL')
export const PUBLIC_URL = Envs.get('PUBLIC_URL')
export const CDN_URL = Envs.get('CDN_URL')

export const BROWSER = {
  NOTIFICATIONS_AVAILABLE: 'Notification' in window,
}

export const API = {
  URL: `${apiProtocol}://${Envs.get('API_URL')}`,
  WS: `${socketProtocol}://${Envs.get('API_URL')}`,
  CHATS: `${apiProtocol}://${Envs.get('CHATS_URL')}`,
  CHATS_WS: `${socketProtocol}://${Envs.get('CHATS_URL')}`,
}

export const STRIPE = {
  KEY: Envs.get('STRIPE_KEY'),
}

export const LINKMINK = {
  KEY: Envs.get('LINKMINK_KEY'),
}

export const COOKIE = {
  SHARED_DOMAIN: `${Envs.get('COOKIE_DOMAIN')}`,
  PREFIX: `worklair-${!IS_PRODUCTION ? 'dev-' : ''}`,
}

export const APOLLO = {
  PERSISTED_QUERIES: Envs.get('PERSISTED_QUERIES') === 'true',
}

export const S3_URL = Envs.get('S3_URL')

export const SENTRY = {
  DSN: Envs.get('SENTRY_DSN'),
}

export const GOOGLE_MAP = {
  KEY: Envs.get('GOOGLE_MAP_KEY'),
}

export const ONESIGNAL = {
  APP_ID: Envs.get('ONESIGNAL_APP_ID'),
}

export const GA = {
  ID: Envs.get('GA_ID'),
}

export const TRELLO = {
  APP_KEY: Envs.get('TRELLO_APP_KEY'),
}

export const REWARDFUL = {
  API_KEY: Envs.get('REWARDFUL_API_KEY'),
}

export const TIDIO = {
  API_KEY: Envs.get('TIDIO_API_KEY'),
}

export const DATOCMS = {
  API_KEY: Envs.get('DATOCMS_API_KEY'),
}

export const MIXPANEL = {
  TOKEN: Envs.get('MIXPANEL_TOKEN'),
}
